import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
// types
const TypePage = () => import("@/views/Types/Show.vue");
const EditType = () => import("@/views/Types/Edit.vue");

// order
const OrderPage = () => import("@/views/Users/orders.vue");
const UserHistoryPage = () => import("@/views/Users/history.vue");

export const alliances = {
    path: "/",
    component: DashboardLayout,
    name: "alliances-Page",
    children: [{
        path: "/alliances",
        name: "alliancesPage",
        component: () =>
            import("@/views/Alliances/index.vue"),
        meta: {
            groupName: "alliances",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
        },
    },

    ],
};

export const Roles = {
    path: "/",
    component: DashboardLayout,
    name: "Roles-Page",
    children: [{
        path: "/roles",
        name: "Roles",
        component: () =>
            import("@/views/Roles/index.vue"),
        meta: {
            groupName: "roles",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "user-role-index",
        },
    },
    {
        path: "/roles/create",
        name: "Create role",
        component: () =>
            import("@/views/Roles/create.vue"),
        meta: {
            groupName: "roles",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "role-create",
        },
    },
    {
        path: "/roles/:id",
        name: "Update role",
        component: () =>
            import("@/views/Roles/update.vue"),
        meta: {
            groupName: "roles",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "role-update",
        },
    },
    ],
};

export const Permissions = {
    path: "/",
    component: DashboardLayout,
    name: "Permissions-Page",
    children: [{
        path: "/permissions",
        name: "Permissions",
        component: () =>
            import("@/views/Permissions/index.vue"),
        meta: {
            groupName: "permissions",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
        },
    },
    {
        path: "/permissions/create",
        name: "Create permission",
        component: () =>
            import("@/views/Permissions/create.vue"),
        meta: {
            groupName: "permissions",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
        },
    },
    {
        path: "/permissions/:id",
        name: "Update permission",
        component: () =>
            import("@/views/Permissions/update.vue"),
        meta: {
            groupName: "permissions",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
        },
    },
    ],
};

export const Admins = {
    path: "/",
    component: DashboardLayout,
    name: "Admins-Page",
    children: [{
        path: "/users",
        name: "Admins",
        component: () =>
            import("@/views/Users/index.vue"),
        meta: {
            groupName: "users",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "user-index",
        },
    },
    {
        path: "/users/create",
        name: "CreateUser",
        component: () =>
            import("@/views/Users/create.vue"),
        meta: {
            groupName: "users",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "user-create",
        },
    },
    {
        path: "/users/update/:id",
        name: "UpdateUser",
        component: () =>
            import("@/views/Users/update.vue"),
        meta: {
            groupName: "users",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "user-update",
        },
    },
    {
        path: "/users/view/:id",
        name: "ViewUser",
        component: () =>
            import("@/views/Users/view.vue"),
        meta: {
            groupName: "users",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "user-view",
        },
    },
    {
        path: "/users/request",
        name: "userRequest",
        component: OrderPage,
        meta: {
            groupName: "users",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "user-request",
        },
    },
    {
        path: "/users/history/:id",
        name: "userHistory",
        component: UserHistoryPage,
        meta: {
            groupName: "users",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "user-history",
        },
    },
    {
        path: "/profile",
        name: "profile",
        component: () =>
            import("@/views/Profile/index.vue"),
        meta: {
            groupName: "users",
            middlewares: [Middleware.auth, Middleware.permission],
        },
    },
    ],
};

export const EmployeePage = {
    path: "/",
    component: DashboardLayout,
    name: "Employee-Page",
    children: [{
        path: "/employees",
        name: "EmployeePage",
        component: () =>
            import("@/views/Employees/index.vue"),
        meta: {
            groupName: "employees",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "user-employee-index",
        },
    },
    {
        path: "/employees/create",
        name: "CreateEmployee",
        component: () =>
            import("@/views/Employees/create_emp.vue"),
        meta: {
            groupName: "employees",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "add-employee",
        },
    },
    ],
};


export const TypesPage = {
    path: "/",
    component: DashboardLayout,
    name: "Type",
    children: [{
        path: "/type/update/:id",
        name: "EditType",
        component: EditType,
        meta: {
            groupName: "Type",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "type-update",
        },
    },
    {
        path: "/type",
        name: "TypePage",
        component: TypePage,
        meta: {
            groupName: "Type",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "user-type-index",
        },
    },
    ],
};