import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export default {
    path: "/cms",
    component: DashboardLayout,
    name: "CMS-Pages",
    children: [{
        path: "news-center",
        name: "NewsCenter",
        component: () =>
            import("@/views/CMS/NewsCenter/index.vue"),
        meta: {
            groupName: "NewsCenter",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "cms-newscenter-index",
        },
    },
    {
        path: "news-center/create",
        name: "Create-NewsCenter",
        component: () =>
            import("@/views/CMS/NewsCenter/create.vue"),
        meta: {
            groupName: "NewsCenter",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "newscenter-create",
        },
    },
    {
        path: "news-center/:id",
        name: "Update-NewsCenter",
        component: () =>
            import("@/views/CMS/NewsCenter/edit.vue"),
        meta: {
            groupName: "NewsCenter",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "newscenter-update",
        },
    },

    {
        path: "newsbar",
        name: "newsbar",
        component: () =>
            import("@/views/CMS/NewsBar/index.vue"),
        meta: {
            groupName: "NewsBar",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "cms-newsbar-index",
        },
    },
    {
        path: "newsbar/create",
        name: "Create-NewsBar",
        component: () =>
            import("@/views/CMS/NewsBar/create.vue"),
        meta: {
            groupName: "NewsBar",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "newsbar-create",
        },
    },
    {
        path: "newsbar/:id",
        name: "Update-NewsBar",
        component: () =>
            import("@/views/CMS/NewsBar/edit.vue"),
        meta: {
            groupName: "NewsBar",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "newsbar-update",
        },
    },
    {
        path: "settings",
        name: "CMSSettings",
        component: () =>
            import("@/views/CMS/Settings/index.vue"),
        meta: {
            groupName: "Settings",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "cms-settings",
        },
    },
    {
        path: "settings/:id",
        name: "Update-Setting",
        component: () =>
            import("@/views/CMS/Settings/edit.vue"),
        meta: {
            groupName: "Settings",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "cms-settings-update",
        },
    },

    {
        path: "eng-guide",
        name: "EngGuide",
        component: () =>
            import("@/views/CMS/EngGuide/index.vue"),
        meta: {
            groupName: "EngGuide",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "cms-newscenter-index",
        },
    },
    {
        path: "eng-guide/create",
        name: "Create-EngGuide",
        component: () =>
            import("@/views/CMS/EngGuide/create.vue"),
        meta: {
            groupName: "EngGuide",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "newscenter-create",
        },
    },
    {
        path: "eng-guide/:id",
        name: "Update-EngGuide",
        component: () =>
            import("@/views/CMS/EngGuide/edit.vue"),
        meta: {
            groupName: "EngGuide",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "newscenter-update",
        },
    },

    ],

}