export const tamplates =
{

    action: "mdi-clipboard-account-outline",
    title: "Tamplates",
    permission: "tamplate-form",
    items: [
        {
            title: "Question View",
            prefix: "K",
            link: "/questions",
            permission: "tamplate-question-index",
        },
        {
            title: "QuestionCategoryView",
            prefix: "K",
            link: "/question-category",
            permission: "tamplate-question_category-index",
        },
        {
            title: "Tamplate Form View",
            prefix: "K",
            link: "/forms",
            permission: "tamplate-form-index",
        },

    ],
};

export const assign_camp = {
    action: "mdi-tent",
    title: "assign camp",
    permission: "delivery",
    items: [
        {
            title: "ShowCamp",
            prefix: "SC",
            link: "/camps",
            permission: "delivery-camp-index",
        },
        {
            title: "ShowSquare",
            prefix: "IS",
            link: "/squares",
            permission: "delivery-square-index",
        },
        {
            title: "assign View",
            prefix: "K",
            link: "/assign",
            permission: "delivery-assign-index",
        },
        {
            title: "Appointments view",
            prefix: "I C",
            link: "/appointments",
            permission: "delivery-appointment-index",
        },
        {
            title: "NewAppointment",
            prefix: "I C",
            link: "/appointments/create",
            permission: "delivery-appointment-creat-index",
        },
        {
            title: "SignaturePage",
            prefix: "SI",
            link: "/contructs",
            permission: "delivery-signature-index",
        },
        {
            title: "ElectricalMetersPage",
            prefix: "IS",
            link: "/electrical-meters",
            permission: "gis-electrical-meter-index",
        },
    ],
};

export const dashboard = {
    action: "fa fa-home",
    title: "Home",
    permission: "dashboard",
    items: [
        {
            title: "Home",
            prefix: "I",
            link: "/dashboard",
            permission: "",
        },
        {
            title: "Data panels",
            prefix: "I",
            link: "/dashboard-power-bi",
            permission: "dashboard-power-bi-1",
        },
        {
            title: "Data panels2",
            prefix: "I",
            link: "/dashboard-power-bi-2",
            permission: "dashboard-power-bi-2",
        },
        {
            title: "PowerBIDashboardarafat",
            prefix: "I",
            link: "/dashboard-power-bi-arafat",
            permission: "dashboard-power-bi-arafat",
        },
        {
            title: "Map gis",
            prefix: "M",
            link: "/map-gis",
            permission: "dashboard-map-1",
        },
        {
            title: "Map gis 2",
            prefix: "M",
            link: "/map-gis-2",
            permission: "dashboard-map-2",
        },
        {
            title: "Map gis 3",
            prefix: "M",
            link: "/map-gis-3",
            permission: "dashboard-map-3",
        },
        {
            title: "OptimizationSites",
            prefix: "M",
            link: "/optimization-sites",
            permission: "dashboard-optimization-sites",
        },
    ],
};
export const user = {
    action: "fa fa-users",
    title: "Users",
    permission: "user",
    items: [
        {
            title: "Admins",
            prefix: "I",
            link: "/users",
            permission: "user-index",
        },
        {
            title: "new main user",
            prefix: "C",
            link: "/users/create",
            permission: "user-create",
        },

        {
            title: "userRequest",
            prefix: "R",
            link: "/users/request",
            permission: "user-request",
        },
        {
            title: "user types",
            prefix: "I",
            link: "/type",
            permission: "user-type-index",
        },
        {
            title: "EmployeePage",
            prefix: "K",
            link: "/employees",
            permission: "user-employee-index",
        },
        {
            title: "Roles",
            prefix: "K",
            link: "/roles",
            permission: "user-role-index",
        },
        {
            title: "CategoryPage",
            prefix: "K",
            link: "/categories",
            permission: "user-category-index",
        },
        {
            prefix: "O",
            title: "alliances",
            link: "/alliances",
            permission: "alliance-index",
        },
    ],
}

export const gis =    /*************** GIS **************/
{
    action: "mdi-map-legend",
    title: "GisPage",
    permission: "gis",
    items: [
        // // location
        // {
        //   title: "LocationPage",
        //   prefix: "IS",
        //   link: "/locations",
        //   permission: "gis-location-index",
        // },
        // // tent
        // {
        //   title: "TentPage",
        //   prefix: "IS",
        //   link: "/tents",
        //   permission: "gis-tent-index",
        // },
        // // kitchen
        // {
        //   title: "KitchenPage",
        //   prefix: "IS",
        //   link: "/kitchens",
        //   permission: "gis-kitchen-index",
        // },
        // // container
        // {
        //   title: "ContainerPage",
        //   prefix: "IS",
        //   link: "/containers",
        //   permission: "gis-container-index",
        // },
        // // zone
        // {
        //   title: "ZonePage",
        //   prefix: "Z",
        //   link: "/zones",
        //   permission: "gis-zone-index",
        // },
        // // plot
        // {
        //   title: "PlotPage",
        //   prefix: "IS",
        //   link: "/plots",
        //   permission: "gis-plot-index",
        // },
        // // establishments
        // {
        //   title: "EstablishmentsPage",
        //   prefix: "IS",
        //   link: "/establishments",
        //   permission: "gis-establishments-index",
        // },
        // // water-tape
        // {
        //   title: "WaterTapePage",
        //   prefix: "IS",
        //   link: "/water-tapes",
        //   permission: "gis-water-tape-index",
        // },
        // // washroom
        // {
        //   title: "WashroomPage",
        //   prefix: "IS",
        //   link: "/washrooms",
        //   permission: "gis-washroom-index",
        // },
        // electrical-meters
        {
            title: "ElectricalMetersPage",
            prefix: "IS",
            link: "/electrical-meters",
            permission: "gis-electrical-meter-index",
        },

    ],
};
