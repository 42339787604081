class EngGuide {
    get(page, data) {
        return axios.get('cms/eng-guide?page=' + page, data)
    }
    get_single(id) {
        return axios.get('cms/eng-guide/edit/' + id)
    }
    add(data) {
        return axios.post('cms/eng-guide/store', data)
    }
    update(id, data) {
        return axios.post('cms/eng-guide/update/' + id, data)
    }
    destroy(id) {
        return axios.delete('cms/eng-guide/destroy/' + id)
    }
}
export default new EngGuide();