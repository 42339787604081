<template>
  <div class="fixed-plugin" :class="showSettingsDrawer == true ? 'show' : ''">
    <v-card class="shadow-lg">
      <div class="card-padding mb-16">
        <div class="float-start">
          <h5 class="text-h5 text-typo font-weight-bold mt-3 mb-0">
            System Configurator
          </h5>
        </div>
        <div class="float-end mt-4">
          <v-btn
            :ripple="false"
            icon
            rounded
            width="20px"
            height="20px"
            class="text-dark"
            @click="$emit('toggleSettingsDrawer', false)"
          >
            <v-icon size="12">fa fa-times</v-icon>
          </v-btn>
        </div>
      </div>
      <hr class="horizontal dark my-1" />
      <div class="card-padding">
        <div>
          <h6 class="text-h6 text-typo font-weight-bold mb-2">
            Sidebar Background
          </h6>
          <v-switch
            :ripple="false"
            class="mt-2 ms-1 pt-0 switch"
            v-model="sidebarBackgroundModel"
            hide-details
            color="#3a416ff2"
            inset
            @change="$emit('toggleSidebarBackground', sidebarBackgroundModel)"
          ></v-switch>
        </div>
        <hr class="horizontal dark mb-3 mt-4" />

        <div class="mt-5">
          <h6 class="text-h6 text-typo font-weight-bold mb-0">
            Main Background
          </h6>
          <v-switch
            :ripple="false"
            class="mt-2 ms-1 pt-0 switch"
            v-model="mainBackgroundModel"
            hide-details
            color="#3a416ff2"
            inset
            @change="$emit('toggleMainBackground', mainBackgroundModel)"
          ></v-switch>
        </div>
        <hr class="horizontal dark mb-3 mt-4" />
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    showSettingsDrawer: {
      type: Boolean,
      default: false,
    },

    sidebarBackground: {
      type: Boolean,
      default: false,
    },

    mainBackground: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainBackgroundModel: this.mainBackground,
      sidebarBackgroundModel: this.sidebarBackground,
    };
  },
};
</script>
