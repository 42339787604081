class ViolationsService {
  get(page, data) {
    return axios.post('general/violation_forms?page=' + page, data)
  }

  get_Assignments(page, data) {
    return axios.post('general/violation_forms_assigns?page=' + page, data)
  }

  delete(id) {
    return axios.delete('general/violation_forms_assigns/' + id)
  }

  getLevels(page, data) {
    return axios.post('general/violation_form_levels?page=' + page, data)
  }

  getByID(id) {
    return axios.get('general/violation_forms/edit/' + id)
  }
  destroy(id) {
    return axios.delete('general/violation_forms/' + id)
  }

}

export default new ViolationsService();