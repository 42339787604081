class NewsCenter {
    get(page, data) {
        return axios.get('cms/news-center')
    }
    get_single(id) {
        return axios.get('cms/news-center/edit/' + id)
    }
    add(data) {
        return axios.post('cms/news-center/store', data)
    }
    update(id, data) {
        return axios.post('cms/news-center/update/' + id, data)
    }
    destroy(id) {
        return axios.delete('cms/news-center/destroy/' + id)
    }
}
export default new NewsCenter();