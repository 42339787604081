class NewsBar {
    get(page, data) {
        return axios.get('cms/news-bar?page=' + page, data)
    }
    get_single(id) {
        return axios.get('cms/news-bar/edit/' + id)
    }
    add(data) {
        return axios.post('cms/news-bar/store', data)
    }
    update(id, data) {
        return axios.post('cms/news-bar/update/' + id, data)
    }
    destroy(id) {
        return axios.delete('cms/news-bar/destroy/' + id)
    }
}
export default new NewsBar();