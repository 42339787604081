class Settings {
    get(page, data) {
        return axios.get('cms/web-settings')
    }
    get_single(id) {
        return axios.get('cms/web-settings/edit/' + id)
    }
    update(id, data) {
        return axios.post('cms/web-settings/update/' + id, data)
    }
}
export default new Settings();