import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "Services",
    children: [
        {
            path: "/services/create",
            name: "NewService",
            component: () => import("@/views/Services/New.vue"),
            meta: {
                groupName: "Service",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "service-create",
            },
        },
        {
            path: "/services/update/:id",
            name: "EditService",
            component: () => import("@/views/Services/Edit.vue"),
            meta: {
                groupName: "Service",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "service-update",
            },
        },
        {
            path: "/services",
            name: "ServicePage",
            component: () => import("@/views/Services/Show.vue"),
            meta: {
                groupName: "Service",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-service-index",
            },
        },
        {
            path: "/services/inputs",
            name: "InputServicePage",
            component: () => import("@/views/Inputs/Show.vue"),
            meta: {
                groupName: "InputService",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-service-input-index",
            },
        },
        {
            path: "/services/inputs/create",
            name: "NewInputService",
            component: () => import("@/views/Inputs/New.vue"),
            meta: {
                groupName: "InputService",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "service-input-create",
            },
        },
        {
            path: "/services/inputs/update/:id",
            name: "EditInputService",
            component: () => import("@/views/Inputs/Edit.vue"),
            meta: {
                groupName: "InputService",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "service-input-update",
            },
        },
    ],

}