class ServiceService {
  get_guides_offices(data) {
    return axios.post('general/guide_offices', data)
  }

  get_guide_offices(id) {
    return axios.get('general/guide_offices/edit/' + id)
  }
  add_guide_offices(data) {
    return axios.post('general/guide_offices/store', data)
  }
  edit_guide_offices(id, data) {
    return axios.post('general/guide_offices/update/' + id, data)
  }

  destroy_guide_offices(id) {
    return axios.delete('general/guide_offices/destroy/' + id)
  }
}
export default new ServiceService();