
class reAssignOrderService {
  get_orders(page, post) {
    return axios.post('general/reassign_order?page=' + page, post)
  }

  add_order(data) {
    return axios.post('general/reassign_order/store', data)
  }

  cancel_camp(id) {
    return axios.post('general/reassign_order/removeOrderFromGroup/' + id)
  }

  re_appointment(data) {
    return axios.post('general/reassign_order/update', data)
  }

  store_krokey(data) {
    return axios.post('general/reassign_kroky_documents/store', data)
  }

  update_krokey(data) {
    return axios.post('general/reassign_kroky_documents/update', data)
  }

  sign_order(data) {
    return axios.post('general/reassign_form/sign-form', data)
  }



  approve_order(data) {
    return axios.post('general/reassign_order_flow/approve', data)
  }

  reject_order(data) {
    return axios.post('general/reassign_order_flow/reject', data)
  }

  // payment attachment

  store_payment(data) {
    return axios.post('general/reassign_payment_documents/store', data)
  }

  update_payment(data) {
    return axios.post('general/reassign_payment_documents/update', data)
  }

  view_payment(order_id, data) {
    return axios.post('general/reassign_payment_documents/view/' + order_id, data)
  }
  delete_payment(id) {
    return axios.post('general/reassign_payment_documents/delete/' + id)
  }

  get_form_signer(data) {
    return axios.post('general/reassign_form/get-form-signer', data)
  }

  update_form_signer(data) {
    return axios.post('general/reassign_form/update-form-signer', data)
  }

  view_kroky(id) {
    return axios.post('general/reassign_kroky_documents/index/' + id)
  }

  store_kroky(data) {
    return axios.post('general/reassign_kroky_documents/store', data)
  }

  delete_kroky(id) {
    return axios.post('general/reassign_kroky_documents/delete/' + id)
  }

}
export default new reAssignOrderService();