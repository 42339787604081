class ServiceService {
  get_services(page, data) {
    return axios.post('general/services?page=' + page, data)
  }
  get_service(id) {
    return axios.get('general/services/edit/' + id)
  }
  add_service(data) {
    return axios.post('general/services/store', data)
  }
  edit_service(id, data) {
    return axios.post('general/services/update/' + id, data)
  }

  destroy_service(id) {
    return axios.delete('general/services/destroy/' + id)
  }
  get_specialist() {
    return axios.get('general/services/create')
  }


  // input service
  get_input_service(id) {
    return axios.get('general/serviceinputs/edit/' + id)
  }
  create_input_service() {
    return axios.get('general/serviceinputs/inputs')
  }
  add_input_service(data) {
    return axios.post('general/serviceinputs/store', data)
  }
  get_input_service(id) {
    return axios.get('general/serviceinputs/edit/' + id)
  }
  edit_input_service(id, data) {
    return axios.post('general/serviceinputs/update/' + id, data)
  }
  destroy_input_service(id) {
    return axios.delete('general/serviceinputs/delete/' + id)
  }
}
export default new ServiceService();