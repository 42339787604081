import router from '../router'
import Vue from "vue";
import i18n from '@/i18n.js'
import LicenseService from '../services/license.service';
export const License = {
    namespaced: true,
    state: () => ({}),
    actions: {
        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("get", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("get", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("get", {})
        },

        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("get", { reset: true })
        },

        get({ rootState }, data) {
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset') && rootState.form.filters) {

                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            return LicenseService.get(rootState.table.paginate.page, formData).then(
                (response) => {
                    rootState.table.items = response.data.order.data
                    rootState.table.paginate = {
                        total: response.data.order.total || response.data.order.data.length,
                        itemsPerPage: parseInt(response.data.order.per_page) || 5,
                        row_pre_page: true,
                        page: rootState.table.paginate.page || 1,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {

                        rootState.form.filters = response.data.filters;
                        rootState.form.filter_year_id = null
                        rootState.form.filter_year = false
                    }

                    if (rootState.form.filter_year_id != null)
                        rootState.form.filter_year = true

                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };

                    }
                    return Promise.reject(error);
                }
            )
        },
    },

};