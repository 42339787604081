class NotificationService {
  get_notifications(page, data) {
    return axios.post('general/notifications?page=' + page, data)
  }
  create_notification() {
    return axios.get('general/notifications/create')
  }
  add_notification(data) {
    return axios.post('general/notifications/store', data)
  }

}
export default new NotificationService();