class QuestionnaireService {
  get_questions(page, data) {
    return axios.get('general/poll-questions?page=' + page, data)
  }
  get_question(id) {
    return axios.get('general/poll-questions/edit/' + id)
  }
  destroy_question(id) {
    return axios.delete('general/poll-questions/' + id)
  }

  get_groups(page, data) {
    return axios.get('general/poll-question-groups?page=' + page, data)
  }
  get_group(id) {
    return axios.get('general/poll-question-groups/edit/' + id)
  }
  destroy_group(id) {
    return axios.delete('general/poll-question-groups/' + id)
  }

  get_questionnaires(page, data) {
    return axios.post('general/questionnaires?page=' + page, data)
  }

  get_MyQuestionnaires(page, data) {
    // console.log(data)
    return axios.post('general/questionnaires-users?page=' + page,data)
  }
  getAnsweredQuestionnaires(page, data) {
    // console.log(data)
    return axios.post('general/questionnaires-users?page=' + page,data)
  }

  get_questionnaire(id) {
    return axios.get('general/questionnaires/edit/' + id)
  }
  destroy_questionnaire(id) {
    return axios.delete('general/questionnaires/' + id)
  }
}

export default new QuestionnaireService();