class ServiceService {
  get_temp_licensess(data) {
    return axios.post('general/temp_licenses', data)
  }

  edit_temp_licenses(id) {
    return axios.get('general/temp_licenses/edit/' + id)
  }
  create_temp_licenses() {
    return axios.get('general/temp_licenses/create')
  }
  add_temp_licenses(data) {
    return axios.post('general/temp_licenses/store', data)
  }
  update_temp_licenses(id, data) {
    return axios.post('general/temp_licenses/update/' + id, data)
  }

  destroy_temp_licenses(id) {
    return axios.delete('general/temp_licenses/destroy/' + id)
  }
}
export default new ServiceService();