export const BACKEND_URLS = {
    PROD: "https://backend.tsleem.com.sa/",
    THECODE: "https://tsleemback.thecode.sa/",
    DEV: "https://backend-dev.tsleem.com.sa/",
    LOCAL_HOST: "http://127.0.0.1:8000/",
};

export const FRONT_URLS = {
    PROD: "https://services.tsleem.com.sa/",
    LOCAL_HOST: "http://localhost:8080/",
    THECODE: "https://tsleem.thecode.sa/",
    DEV: "https://services-dev.tsleem.com.sa/",
    ARAFAT: "https://arafat-dev.tsleem.com.sa/",
    MUZDALIFA: "https://muzdalifa-dev.tsleem.com.sa/",
    GOV: "https://government-dev.tsleem.com.sa/",
    COMPLAINT: "https://report-dev.tsleem.com.sa/"	

};
export const CURRENT_URL_BACK = BACKEND_URLS.DEV
export const CURRENT_URL_FRONT = FRONT_URLS.DEV
