export default function permission({ to, nextMiddleware, contextNext, router, store, from }) {
  if (store.state.auth.permissions.length == 0) {
    store.dispatch("auth/getPermission").then((res) => {
      checkPer();
    });
  } else {
    checkPer();
  }

  function checkPer() {
    if (to.meta.permissions) {
      // if (
      //   (["add-employee"].includes(to.meta.permissions) &&
      //     store.state.auth.user.parent_id != null) ||
      //   (["add-employee"].includes(to.meta.permissions) &&
      //     store.state.auth.type.code == "admin")
      // ) {
      //   return contextNext({
      //     path: "/403",
      //   });
      // }
      if (!store.state.auth.permissions.includes(to.meta.permissions)) {
        return contextNext({
          path: "/403",
        });
      }
    }
  }

  return nextMiddleware();
}
