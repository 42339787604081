import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export default {
    path: "/",
    component: DashboardLayout,
    name: "Ticket",
    children: [
        {
            path: "tickets/ticket-subject",
            name: "TicketSubjectPage",
            component: () => import("@/views/Ticket/Subject/Show.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-subject-index',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
        {
            path: "tickets/ticket-subject/create",
            name: "TicketSubjectCreatePage",
            component: () => import("@/views/Ticket/Subject/New.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-subject-create',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
        {
            path: "tickets/ticket-subject/edit/:id",
            name: "TicketSubjectEditPage",
            component: () => import("@/views/Ticket/Subject/Edit.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-subject-update',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
        {
            path: "tickets",
            name: "TicketPage",
            component: () => import("@/views/Ticket/Show.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-index',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
        {
            path: "tickets/create",
            name: "TicketCreatePage",
            component: () => import("@/views/Ticket/New.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-create',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
        {
            path: "tickets/details/:id",
            name: "DetailsTicket",
            component: () => import("@/views/Ticket/Details.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-details',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
    ]
}