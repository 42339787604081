export const questionnaires = {
    action: "fa fa-question",
    title: "Questionnaires",
    permission: "view-questionnaires",
    items: [
        {
            title: "questionnairesQuestions",
            prefix: "Q",
            link: "/questionnaires/questions",
            permission: "view-questionnaire-question",
        },
        {
            title: "questionnairesGroups",
            prefix: "S",
            link: "/questionnaires/groups",
            permission: "view-questionnaire-group",
        },
        {
            title: "Questionnaires",
            prefix: "Q",
            link: "/questionnaires",
            permission: "view-questionnaires",
        },
        {
            title: "View Questionnaires",
            prefix: "Q",
            link: "/questionnaires/usersQuestionnaires",
            permission: "view-users-questionnaire",
        },
    ],
}

export const myQuestionnaires = {
    action: "fa fa-question",
    title: "MyQuestionnaires",
    permission: "view-my-questionnaires",
    items: [
        {
            title: "Answered Questionnaires",
            prefix: "A",
            link: "/questionnaires/answeredQuestionnaires",
            permission: "",
        },
        {
            title: "Pending Questionnaires",
            prefix: "P",
            link: "/questionnaires/pendingQuestionnaires",
            permission: "",
        },
    ],
}

export const violationForms = {
    action: "fa fa-hand-paper",
    title: "Violations",
    permission: "view-violations",
    items: [
        {
            title: "violationForms",
            prefix: "F",
            link: "/violations",
            permission: "view-violation-form",
        },
        {
            title: "violationAssignments",
            prefix: "A",
            link: "/violations/assignments",
            permission: "view-violations",
        },
        {
            title: "violationLevels",
            prefix: "L",
            link: "/violations/levels",
            permission: "view-violation-levels",
        },
    ],
}


export const publicBenefits = {
    action: "fa fa-th-large",
    title: "publicBenefits",
    permission: "view-public-benefits",
    items: [
        {
            title: "publicBenefits",
            prefix: "P",
            link: "/publicBenefits",
            permission: "view-public-benefits",
        },
        {
            title: "publicBenefitsCamps",
            prefix: "C",
            link: "/publicBenefits/camps",
            permission: "view-public-benefits-assignments",
        },
    ],
}



export const notifications = {
    action: "mdi-bell",
    title: "NotificationPage",
    link: "/notifications",
    permission: "notification-index",
}
