class ServiceService {
  get_pledges(url, page, data) {
    return axios.post(url + '?page=' + page, data)
  }
  get_pledge(id) {
    return axios.get('general/pledges/edit/' + id)
  }
  add_pledge(data) {
    return axios.post('general/pledges/store', data)
  }
  edit_pledge(id, data) {
    return axios.post('general/pledges/update/' + id, data)
  }

  destroy_pledge(id) {
    return axios.delete('general/pledges/destroy/' + id)
  }
  create() {
    return axios.get('general/pledges/create')
  }

  destroy_fire_pledge(id) {
    return axios.delete('general/' + id)
  }
}
export default new ServiceService();