import TicketService from '../services/ticket.service'
import i18n from '@/i18n.js'
import Vue from "vue";
export const ticket_subject = {
    namespaced: true,

    actions: {
        // select_user({},){}
        select_all_user({ rootState }, value) {
            if (value == true) {
                rootState.form.style_form.map(function (v) {
                    if (v.value_text == "allowedUsers") v.visible = false
                    else if (v.value_text == "type_id") v.col = 6
                    else if (v.value_text == "allUser") v.col = 6
                    return v;
                });
            } else {
                rootState.form.style_form.map(function (v) {
                    if (v.value_text == "allowedUsers") v.visible = true
                    else if (v.value_text == "type_id") v.col = 4
                    else if (v.value_text == "allUser") v.col = 4
                    return v;
                });
            }
        },
        select_type({ rootState, commit }, value) {
            if (value == 4) {
                rootState.form.style_form.map(function (v) {
                    if (v.value_text == "allowedUsers") v.visible = true
                    else if (v.value_text == "type_id") v.col = 4
                    else if (v.value_text == "allUser") v.visible = true
                    return v;
                });
            } else {
                rootState.form.style_form.map(function (v) {
                    if (v.value_text == "allowedUsers") v.visible = false
                    else if (v.value_text == "type_id") v.col = 12
                    else if (v.value_text == "allUser") v.visible = false
                    return v;
                });
            }
            // axios.get("general/reassign_form/get-user-of-type/" + value).then(
            //     (response) => {
            //         commit('SET_CARD_LOADING', false, { root: true });
            //         console.log(response.data);
            //         rootState.form.style_form.map(function (v) {
            //             if (v.value_text == "allowedUsers")
            //                 var data = response.data;
            //             data.unshift({ 'id': 'all', name: 'كل المستخدمين' })
            //             return (v.items = data);
            //         });
            //     },
            //     (error) => { })
        },
        destroy({ rootState }, item) {
            Vue.swal({
                title: i18n.t("Are you sure?"),
                text: i18n.t("You won't be able to revert this!"),
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: i18n.t("Yes, delete it!"),
                cancelButtonText: i18n.t("No, cancel!"),
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    return TicketService.destroy_ticket_subject(item.id).then(
                        (response) => {
                            // console.log(response)
                            rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                            Vue.swal.fire(i18n.t("Deleted"), response.data.message, "success");
                        },
                        (error) => {
                            console.log(error);
                            if (error.response.status != 401) {
                                Vue.swal.fire(
                                    i18n.t("Error"),
                                    i18n.t("There error please try again"),
                                    "error"
                                );
                            }
                        }
                    );
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Vue.swal.DismissReason.cancel
                ) {
                    Vue.swal.fire(i18n.t("Cancelled"), i18n.t("Cancelled Delete"), "error");
                }
            });
        },
        add({ rootState }, data) {
            return TicketService.add_ticket_subject(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    // rootState.form.style_form = [];
                    rootState.form.dialog = false
                    rootState.table.items.push(response.data.data)
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            );

        },
        edit({ commit, rootState }, item) {
            rootState.form.modal.title = i18n.t("edit Ticket Subject");
            var users = []
            for (let y = 0; y < item.get_restriction.length; y++) {
                const v = item.get_restriction[y];
                users.push(parseInt(v.user_id));
            }

            rootState.form.style_form.map(function (v) {
                if (v.value_text == "allowedUsers") {
                    v.visible = true
                    v.value = users
                }
                else if (v.value_text == "type_id") {
                    v.col = 4
                    v.value = item.type_id
                } else if (v.value_text == "subject") v.value = item.subject
                else if (v.value_text == "note") v.value = item.note
                return v;
            });

            commit("form/SET_DIALOG", true, { root: true });
            commit("SET_FUNCTION", "update", { root: true });
            commit("SET_ID", item.id, { root: true });
        },
        update({ rootState, state }, data) {
            return TicketService.update_ticket_subject(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    // rootState.form.style_form = [];
                    rootState.form.dialog = false;
                    rootState.table.items = rootState.table.items.filter(v => v.id != rootState.id)
                    rootState.table.items.push(response.data.data)
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            );
        },
    },
};