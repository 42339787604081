import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export default {
    path: "/questionnaires",
    component: DashboardLayout,
    name: "questionnaires",
    children: [
        {
            path: "/",
            name: "Questionnaires",
            component: () =>
                import("@/views/Questionnaires/Questionnaires/index.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                // permissions: "order",
            },
        },
        {
        path: "new",
        name: "addNewQuestionnaire",
        component: () =>
            import("@/views/Questionnaires/Questionnaires/new.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "add-questionnaire",
        },
    },
    
    {
        path: "edit/:id",
        name: "UpdateQuestionnaire",
        component: () =>
            import("@/views/Questionnaires/Questionnaires/new.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "edit-questionnaire",
        },
    },

    /* my questionaires mean: questioners assigned to me */
    {
        path: "answeredQuestionnaires",
        name: "myAnsweredQuestionnaires",
        component: () =>
            import("@/views/Questionnaires/Questionnaires/myQuestionnaires.vue"),
        meta: {
            groupName: "MyQuestionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            title: 'myAnsweredQuestionnaires'
        },
    },
    {
        path: "pendingQuestionnaires",
        name: "myPendingQuestionnaires",
        component: () =>
            import("@/views/Questionnaires/Questionnaires/myQuestionnaires.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            title: 'myPendingQuestionnaires'
        },
    },
    

    {
        path: "myQuestionnaires/:id",
        name: "myQuestionnaire",
        component: () =>
            import("@/views/Questionnaires/Questionnaires/submitAnswer.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            // permissions: "",
        },
    },

/* users Questionnaires mean: questioners assigned to any user(only admin can view this page) */

    {
        path: "usersQuestionnaires",
        name: "UsersQuestionnaires",
        component: () =>
            import("@/views/Questionnaires/Questionnaires/usersQuestionnaires.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "view-users-questionnaire",
        },
    },
    {
        path: "usersQuestionnaires/:id",
        name: "usersQuestionnaire",
        component: () =>
            import("@/views/Questionnaires/Questionnaires/submitAnswer.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "view-users-questionnaire",
        },
    },
   

    {
        path: "questions/new",
        name: "addNewQuestions",
        component: () =>
            import("@/views/Questionnaires/Questions/new.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "add-questionnaire-question",
        },
    },
    {
        path: "questions/:id",
        name: "updateQuestion",
        component: () =>
            import("@/views/Questionnaires/Questions/new.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "edit-questionnaire-question",
        },
    },
    {
        path: "questions",
        name: "questionnairesQuestions",
        component: () =>
            import("@/views/Questionnaires/Questions/index.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "view-questionnaire-question",
        },
    },
    

    {
        path: "groups/new",
        name: "addNewGroup",
        component: () =>
            import("@/views/Questionnaires/Groups/new.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "add-questionnaire-group",
        },
    },
    {
        path: "groups/:id",
        name: "updateGroups",
        component: () =>
            import("@/views/Questionnaires/Groups/new.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "edit-questionnaire-group",
        },
    },
    {
        path: "groups",
        name: "questionnairesGroups",
        component: () =>
            import("@/views/Questionnaires/Groups/index.vue"),
        meta: {
            groupName: "Questionnaires",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "view-questionnaire-group",
        },
    },


    
],


}