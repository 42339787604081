import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export default {
    path: "/violations",
    component: DashboardLayout,
    name: "violations",
    children: [
        {
            path: "/",
            name: "violationForms",
            component: () =>
                import("@/views/Violations/index.vue"),
            meta: {
                groupName: "Violations",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "view-violation-form",
            },
        },
        {
            path: "new",
            name: "addNewViolationForm",
            component: () =>
                import("@/views/Violations/new.vue"),
            meta: {
                groupName: "Violations",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "add-violation-form",
            },
        },

        {
            path: "edit/:id",
            name: "UpdateViolationForm",
            component: () =>
                import("@/views/Violations/new.vue"),
            meta: {
                groupName: "Violations",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "edit-violation-form",
            },
        },

        {
            path: "assign/:id",
            name: "AssignViolationForm",
            component: () =>
                import("@/views/Violations/assign.vue"),
            meta: {
                groupName: "Violations",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "add-violation",
            },
        },

        {
            path: "assign",
            name: "AssignViolationForm",
            component: () =>
                import("@/views/Violations/assign.vue"),
            meta: {
                groupName: "Violations",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "add-violation",
            },
        },

        {
            path: "assignments",
            name: "violationAssignments",
            component: () =>
                import("@/views/Violations/assignments.vue"),
            meta: {
                groupName: "Violations",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                // permissions: "view-violations",
            },
        },

        {
            path: "view/:id",
            name: "ViewViolation",
            component: () =>
                import("@/views/Violations/view.vue"),
            meta: {
                groupName: "Violations",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                // permissions: "view-violation",
            },
        },


        {
            path: "levels",
            name: "violationLevels",
            component: () =>
                import("@/views/Violations/levels/index.vue"),
            meta: {
                groupName: "Violations",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "view-violation-levels",
            },
        },
        
        {
            path: "levels/new",
            name: "newViolationLevels",
            component: () =>
                import("@/views/Violations/levels/new.vue"),
            meta: {
                groupName: "Violations",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "add-violation-level",
            },
        },
        {
            path: "levels/:id",
            name: "updateViolationLevels",
            component: () =>
                import("@/views/Violations/levels/new.vue"),
            meta: {
                groupName: "Violations",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "edit-violation-level",
            },
        },


    ],


}