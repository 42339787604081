class publicBenefitsService {
  get(page, data) {
    return axios.post('general/public-benefits?page=' + page, data)
  }

  get_camps(page, data) {
    return axios.post('general/public-benefits/camps?page=' + page, data)
  }

  // getByID(id) {
  //   return axios.get('general/violation_forms/edit/' + id)
  // }
  destroy(id) {
    return axios.delete('general/public-benefits/' + id)
  }
}

export default new publicBenefitsService();