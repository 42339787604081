
export default function is_admin({
    nextMiddleware,
    store,
    contextNext,
}) {
    console.log(store.state.auth.type)
    if (store.state.auth.type && store.state.auth.type.code == 'admin') {
        return nextMiddleware();
    } else {
        return contextNext({
            path: "/dashboard",
        });
    }
}
